<template>
  <div
    class="mpz-form green"
    :class="{type}"
  >
    <div class="mpz-form__title title-3 green" v-if="!type">Если остались вопросы или есть предложения</div>
    <div class="mpz-form__title title-3 green" v-else>Оставьте свои <span>контакты</span>, мы все <span>сделаем за вас</span></div>

    <div class="mpz-form__item">
      <MPZInput
        v-model="name"
        placeholder="Имя"
      />
    </div>
    <div class="mpz-form__item">
      <MPZInput
        v-model="phone"
        placeholder="Номер телефона"
        masked="tel"
      />
    </div>
    <div class="mpz-form__item" v-if="!type">
      <MPZInput
        v-model="email"
        placeholder="E-mail"
      />
    </div>
    <div class="mpz-form__item" v-if="!type">
      <textarea
        v-model="message"
        placeholder="Cообщение (макс. 90 символов)"
        maxlength="90"
        @input="changeTextareaHeight"
        ref="textarea"
      ></textarea>
    </div>

    <div class="button">
      <MPZButton
        label="Отправить"
        :disabled="type ? !validateType : !validate"
        @clicked="sendForm"
        green
      />
    </div>

    <transition name="default">
      <MPZModalSendForm
        v-if="isShowMPZModalSendForm"
        @close="isShowMPZModalSendForm = false"
      />
    </transition>

  </div>
</template>

<script setup>
import {defineProps, ref, computed} from 'vue'
import MPZModalSendForm from '@/components/modals/MPZModalSendForm.vue'
import useApi from '@/services/useApi'
import formattedPhone from '@/helpers/formattedPhone.js'

const props = defineProps({
  type: {
    type: String,
    default: ''
  }
})

const isShowMPZModalSendForm = ref(false)

const name = ref('')
const phone = ref('')
const email = ref('')
const message = ref('')

const textarea = ref(null);



const sendForm = async() => {
  const payload = props.type ? {
    name: name.value,
    phone: formattedPhone(phone.value),
    subject: props.type
  } : {
    name: name.value,
    phone: formattedPhone(phone.value),
    email: email.value,
    msg: message.value
  }

  const req = await useApi('post', props.type ? 'request' : 'sentence', payload)

  if(req.status === 'successful'){
      isShowMPZModalSendForm.value = true
      name.value = ''
      phone.value = ''
      email.value = ''
      message.value = ''
    }
}

const validate = computed(() => name.value.length > 2 && phone.value.length === 17 && email.value.includes('@') && email.value.includes('.'))

const validateType = computed(() => name.value.length > 2 && phone.value.length === 17)

const changeTextareaHeight = () => {
  textarea.value.style.height = (textarea.value.scrollHeight > textarea.value.clientHeight) ? (textarea.value.scrollHeight)+"px" : "45.5px";
}

</script>

<style scoped lang="scss">
.mpz-form{
  width: 100%;
  padding: 30px;
  background: #F7F7F7;
  border-radius: 30px;
  background-color: #EAFFEB;
  &__title{
    margin-bottom: 20px;
    color: rgba(22, 22, 22, 0.5);
    span{
      color: rgba(22, 22, 22, 1);
      font-size: 24px;
      line-height: 100%;
      font-family: "SFProDisplay-Medium";
    }
    &.green{
      color: #7DAF7F;
      span{
        color: #2F6031;
      }
    }
  }
  &__item{
    margin-bottom: 16px;
  }
  &.type{
    height: max-content;
    background-color: #EAFFEB;
  }
  .button {
    margin-top: 30px;
  }
}
</style>
